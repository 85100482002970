import React, { useState, useEffect, useContext, useRef } from "react";
import { useParams, generatePath, useHistory } from "react-router-dom";
import Cookies from 'universal-cookie';
import axios from "axios";
import { scrollToTop, translateTerm } from "../../util/helper";
import Swal from 'sweetalert2';
import {FormattedMessage, useIntl} from 'react-intl';
import { FirebaseContext } from "../../contexts/firebase.context";
import { RouteContext } from "../../contexts/route.context";
import { DateTime } from "luxon";
import { Carousel, Modal, Button } from 'react-bootstrap';
import LeaseMap from "./leasemap";
import defaultAvatar from "../../assets/images/profpic.svg";
import { useGeolocated } from "react-geolocated";

const MyLeaseSingleActive = () => {
    const cookies = new Cookies();
    const history = useHistory();
    const intl = useIntl();
    const { id } = useParams();
    const ref = useRef(null);
    const { baseURL, apiHeaders, getMe, me, getPostReviews, postReviews, getChatList, chatList, getActiveBookings } = useContext(RouteContext);
    let fromPage = "myleases";
    const { database } = useContext(FirebaseContext);
    const [bannerIndex, setBannerIndex] = useState(0);
    const [numReview, setNumReview] = useState(5);
    const [pageReview, setpageReview] = useState(1);
    const [showDetails, setShowDetails] = useState(false);
    const [pricePerTerm, setPricePerTerm] = useState(2);
    const [showModalReview, setShowModalReview] = useState(false);
    const [starRateClick, setStarRateClick] = useState();
    const [rateComment, setRateComment] = useState("");
    const [mapShowModal, setMapShowModal] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [chosenChatMsg, setChosenChatMsg] = useState("");
    const [chosenChatLoading, setChosenChatLoading] = useState(false);

    const handleCloseMapModal = () => setMapShowModal(false);
    const handleCloseModal = () => setShowModal(false);
    const [singePost, setSingePost] = useState(); 
    
    const { coords, isGeolocationAvailable, isGeolocationEnabled } =
    useGeolocated({
        positionOptions: {
            enableHighAccuracy: false,
        },
        userDecisionTimeout: 5000,
    });
    

    const getActiveBookingSingle = async () => {
        let input = `{user_role: lessee, booking_id: "${id}"}`
        let result = await getActiveBookings(5, 1, input)
        setSingePost(result);
        // setEndDate(new Date(result.data[0].end_date))
    }

    useEffect(() => {
        getMe();
        getActiveBookingSingle()
    }, []);

    useEffect(() => {
        let pricePerTerm = singePost?.data[0].post_lease_term.price;
        setPricePerTerm(pricePerTerm);
    
        let lessorID = singePost?.data[0].lessor.id;
        let userID = singePost?.data[0].user.id;
        let postID = singePost?.data[0].post.id;
        let reviewInput = `user_id: ${userID}, post_id: ${postID}`;
        getPostReviews(numReview, pageReview, lessorID, reviewInput);
      }, [singePost?.data[0]]);

    const handleSelectBanner = (selectedIndex, e) => {
        setBannerIndex(selectedIndex);
    };

    const onPrevClick = () => {
        ref.current.prev();
      };
      const onNextClick = () => {
        ref.current.next();
      };
    
      const handleCloseModalReview = () => setShowModalReview(false);
    
      const onClickStarRate = (rate) => {
        setStarRateClick(rate);
      }

      const onSubmitRate = async() => {
        let lessorID = singePost?.data[0].lessor.id;
        let userID = singePost?.data[0].user.id;
        let postID = id;
        let reviewInput = `user_id: ${userID}, post_id: ${postID}`;
    
        let bookID = id;
    
        let result;
    
        if(rateComment !== "") {
          result = await axios({
            url: baseURL,
            headers: apiHeaders,
            method: "post",
            data: {
              query: `
                mutation {
                  StorePostReview(input: {booking_id: "${bookID}", star: "${starRateClick}", comment: "${rateComment}"}){
                    id
                    status
                    message
                  }
                }
              `
            }
          });
        }
        else {
          result = await axios({
            url: baseURL,
            headers: apiHeaders,
            method: "post",
            data: {
              query: `
                mutation {
                  StorePostReview(input: {booking_id: "${bookID}", star: "${starRateClick}"}){
                    id
                    status
                    message
                  }
                }
              `
            }
          });
        }
    
        if(result.data.data) {
          Swal.fire({
            title: 'Added a Review',
            text: "",
            icon: 'success',
            confirmButtonColor: '#ff9035',
          }).then((e) => {
            getPostReviews(numReview, pageReview, lessorID, reviewInput);
            handleCloseModalReview();
          });
        }
        else {
    
          Swal.fire({
            title: 'Added Review Failed',
            text: result.data.errors[0].message + "\n" + result.data.errors[0].extensions.reason,
            icon: 'warning',
            confirmButtonColor: '#ff9035',
          }).then((e) => {
            getPostReviews(numReview, pageReview, lessorID, reviewInput);
            handleCloseModalReview();
          });
    
        }
      }

      const onClickCancel = async() => {
        let bookID = id;
    
        Swal.fire({
          title: 'Do you want to cancel booking?',
          showDenyButton: true,
          showCancelButton: true,
          confirmButtonText: 'Yes',
          confirmButtonColor: '#25D676',
          denyButtonText: `Don't Cancel`,
        }).then(async (result) => {
          if (result.isConfirmed) {
            
            let result = await axios({
              url: baseURL,
              headers: apiHeaders,
              method: "post",
              data: {
                query: `
                  mutation {
                    cancelBooking(booking_id: "${bookID}"){
                      id
                      status
                      message
                    }
                  }
                `
              }
            });
    
            if(result.data.data) {
              Swal.fire({
                title: 'Cancel a Booking',
                text: "",
                icon: 'success',
                confirmButtonColor: '#ff9035',
              }).then((e) => {
                window.location.reload();
              });
            }
            else {
    
              Swal.fire({
                title: 'Cancel Booking Failed',
                text: result.data.errors[0].message,
                icon: 'warning',
                confirmButtonColor: '#ff9035',
              }).then((e) => {
                window.location.reload();
              });
    
            }
    
          }
        });
      }

      const onClickLessor = () => {
        cookies.set('view_lessor_id', singePost?.data[0].lessor.id);
        // window.location.href = "/lessor-profile";
        history.push(generatePath("/lessor-profile/:id", {id: singePost?.data[0].lessor.id}));
      }
    
    
      const changeMsg = (msg) => {
        setChosenChatLoading(true);
        setChosenChatMsg(msg);
        setTimeout(() => setChosenChatLoading(false), 200);
      }

      function updateLastMsgUser() {
        let refUsers = "users/" + me.id;
        let usersFilteredRef = database
          .ref(refUsers)
          .orderByChild("user_id")
          .equalTo(singePost?.data[0].lessor.id);
        let userKey;
    
        usersFilteredRef.once("value", (snapshot) => {
          const usersObj = (snapshot && snapshot.val()) || {};
    
          const usersArr =
            (usersObj &&
              Object.keys(usersObj) &&
              Object.keys(usersObj).length &&
              Object.keys(usersObj).map((key) => {
                usersObj[key].key = key;
                // delete usersObj[key].children;
                return usersObj[key];
              })) ||
            [];
    
          userKey = usersArr[0].key;
    
          let refUserFinal = refUsers + "/" + userKey;
      
          database
            .ref(refUserFinal)
            .update({
              date: DateTime.now().toFormat('MMMM dd, yyyy'),
              last_message: chosenChatMsg,
              time: new Date().toLocaleTimeString().replace(/(.*)\D\d+/, '$1'),
            });
        });
        
      }

      const onSubmitChat = () => {
        if(chatList && chatList.length !== 0) {
          if(chosenChatMsg !== "") {
            let chatDetails = {
              attachment: "",
              customer_id: singePost?.data[0].lessor.id,
              date: DateTime.now().toFormat('MMMM dd, yyyy'),
              message: chosenChatMsg,
              sender_id: me.id,
              time: new Date().toLocaleTimeString().replace(/(.*)\D\d+/, '$1'),
            }
            
            let refChat = "room/fb_user" + me.id + "_fb_user" + singePost?.data[0].lessor.id;
            database.ref(refChat).push(chatDetails);
            updateLastMsgUser();
            setChosenChatMsg("");
            setShowModal(false);
          }
        }
        else {
          let chatUserDetailsLessee = {
            chat_count:1,
            date: DateTime.now().toFormat('MMMM dd, yyyy'),
            last_message: chosenChatMsg,
            time: new Date().toLocaleTimeString().replace(/(.*)\D\d+/, '$1'),
            user_id: singePost?.data[0].lessor.id,
          }
          let chatUserDetailsLessor = {
            chat_count:1,
            date: DateTime.now().toFormat('MMMM dd, yyyy'),
            last_message: chosenChatMsg,
            time: new Date().toLocaleTimeString().replace(/(.*)\D\d+/, '$1'),
            user_id: me.id,
          }
          
          let refLesseeUserChat = "users/" + me.id;
          database.ref(refLesseeUserChat).push(chatUserDetailsLessee);
    
          let refLessorUserChat = "users/" + singePost?.data[0].lessor.id;
          database.ref(refLessorUserChat).push(chatUserDetailsLessor);
    
          
          if(chosenChatMsg !== "") {
            let chatDetails = {
              attachment: "",
              customer_id: singePost?.data[0].lessor.id,
              date: DateTime.now().toFormat('MMMM dd, yyyy'),
              message: chosenChatMsg,
              sender_id: me.id,
              time: new Date().toLocaleTimeString().replace(/(.*)\D\d+/, '$1'),
            }
            
            let refChat = "room/fb_user" + me.id + "_fb_user" + singePost?.data[0].lessor.id;
            database.ref(refChat).push(chatDetails);
            updateLastMsgUser();
            setChosenChatMsg("");
            setShowModal(false);
          }
        }
    
        getChatList(me.id, singePost?.data[0].lessor.id);
      }

      const onMapClicked = () => {
        if(isGeolocationAvailable){
          if(isGeolocationEnabled){
            window.open("https://www.google.com/maps/dir/"+coords?.latitude+","+coords?.longitude+"/"+singePost?.data[0].post.latitude+","+singePost?.data[0].post.longitude)
          }
          else{
             window.open("https://maps.google.com?q="+singePost?.data[0].post.latitude+","+singePost?.data[0].post.longitude);
          }
        }
        else{
          window.open("https://maps.google.com?q="+singePost?.data[0].post.latitude+","+singePost?.data[0].post.longitude);
        }
      }
    
    return (
        <div>
            <div className="container-fluid leases-single-div">
                <div className="row">
                <div className="col-12 px-0 position-relative carousel-lease-single-div">
                    <Carousel 
                    ref={ref} 
                    controls={false} 
                    indicators={false} 
                    activeIndex={bannerIndex} 
                    onSelect={handleSelectBanner}
                    >
                    {singePost?.data[0].post.post_images.map((img, index) => (
                        <Carousel.Item key={index}>
                        <img
                            className="d-block w-100"
                            src={img.image}
                            // alt="First slide"
                        />
                        </Carousel.Item>
                    ))}
                    
                    </Carousel>

                    <div className="carousel-lease-single">
                    <button className="btn btn-back" onClick={onPrevClick}>
                        <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M7.24863 11.6483C7.4736 11.4233 7.59998 11.1181 7.59998 10.7999C7.59998 10.4817 7.4736 10.1765 7.24863 9.9515L3.29703 5.9999L7.24863 2.0483C7.46722 1.82198 7.58818 1.51886 7.58544 1.20422C7.58271 0.889585 7.4565 0.58861 7.23401 0.366121C7.01152 0.143631 6.71055 0.0174279 6.39591 0.0146938C6.08128 0.0119597 5.77815 0.132913 5.55183 0.351503L0.751832 5.1515C0.526866 5.37654 0.400488 5.68171 0.400488 5.9999C0.400488 6.3181 0.526866 6.62327 0.751832 6.8483L5.55183 11.6483C5.77687 11.8733 6.08203 11.9996 6.40023 11.9996C6.71843 11.9996 7.0236 11.8733 7.24863 11.6483Z" fill="#AAAAAA"/>
                        </svg>
                    </button>
                    <button className="btn btn-next" onClick={onNextClick}>
                        <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M0.751368 11.6483C0.526403 11.4233 0.400024 11.1181 0.400024 10.7999C0.400024 10.4817 0.526403 10.1765 0.751368 9.9515L4.70297 5.9999L0.751368 2.0483C0.532779 1.82198 0.411825 1.51886 0.414559 1.20422C0.417293 0.889585 0.543496 0.58861 0.765986 0.366121C0.988476 0.143631 1.28945 0.0174279 1.60409 0.0146938C1.91872 0.0119597 2.22185 0.132913 2.44817 0.351503L7.24817 5.1515C7.47313 5.37654 7.59951 5.68171 7.59951 5.9999C7.59951 6.3181 7.47313 6.62327 7.24817 6.8483L2.44817 11.6483C2.22313 11.8733 1.91797 11.9996 1.59977 11.9996C1.28157 11.9996 0.976402 11.8733 0.751368 11.6483Z" fill="#FF5833"/>
                        </svg>
                    </button>
                    </div>
                </div>
                </div>
            </div>
            <div className="container main-wrap">
                <div className="row">
                <div className="col-12 col-lg-7">
                    <div className="lease-single-content-div">
                    <p className="cred fw700 fs14 mb-2">{intl.locale === "en" ? singePost?.data[0].post.category.en : singePost?.data[0].post.category.ar}</p>
                    <p className="cblue fw600 mb-2 lease-single-name">
                        {singePost?.data[0].post.title}
                        {/* <span className="cur-pointer" onClick={() => setMapShowModal(true)}>
                        <svg width="22" height="24" viewBox="0 0 26 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M16.2 0.537598L9.80001 6.9376V27.4624L16.2 21.0624V0.537598ZM2.93121 3.2688C2.70745 3.0451 2.42239 2.89277 2.11206 2.83106C1.80174 2.76936 1.48008 2.80104 1.18776 2.92211C0.895445 3.04319 0.645585 3.24821 0.46977 3.51126C0.293955 3.77432 0.20008 4.0836 0.200012 4.4V20.4C0.200103 20.8243 0.368733 21.2312 0.668812 21.5312L6.60001 27.4624V6.9376L2.93121 3.2688ZM25.3312 6.4688L19.4 0.537598V21.0624L23.0688 24.7312C23.2926 24.9549 23.5776 25.1072 23.888 25.1689C24.1983 25.2306 24.5199 25.199 24.8123 25.0779C25.1046 24.9568 25.3544 24.7518 25.5303 24.4887C25.7061 24.2257 25.7999 23.9164 25.8 23.6V7.6C25.7999 7.17569 25.6313 6.76879 25.3312 6.4688Z" fill="white"/>
                        </svg>
                        </span> */}
                    </p>
                    {/* <h3 className="fw800 cblack fs30 mb-5">SAR {pricePerTerm && pricePerTerm[0].price} <span className="fs16">/ {translateTerm(post.lease_term)}</span></h3> */}
                    <p className="cgray mb-3"><span className="fw700 cblack"><FormattedMessage 
                        id="createdescpost.text"
                        defaultMessage="Create Description on your post"
                        description="Create Description on your post"
                    />:</span> {singePost?.data[0].post.description}</p>
                    <p className="cred fs14 mb-2">
                        <svg width="18" height="18" 
                        style={{marginRight: intl.locale ==="en" ? "10px" : "0px", marginLeft: intl.locale === "en" ? "0px" : "10px"}} 
                        viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M5.20002 0.400391C4.88176 0.400391 4.57654 0.526819 4.3515 0.751862C4.12645 0.976906 4.00002 1.28213 4.00002 1.60039V2.80039H2.80002C2.1635 2.80039 1.55306 3.05325 1.10297 3.50333C0.652881 3.95342 0.400024 4.56387 0.400024 5.20039V17.2004C0.400024 17.8369 0.652881 18.4474 1.10297 18.8974C1.55306 19.3475 2.1635 19.6004 2.80002 19.6004H17.2C17.8365 19.6004 18.447 19.3475 18.8971 18.8974C19.3472 18.4474 19.6 17.8369 19.6 17.2004V5.20039C19.6 4.56387 19.3472 3.95342 18.8971 3.50333C18.447 3.05325 17.8365 2.80039 17.2 2.80039H16V1.60039C16 1.28213 15.8736 0.976906 15.6486 0.751862C15.4235 0.526819 15.1183 0.400391 14.8 0.400391C14.4818 0.400391 14.1765 0.526819 13.9515 0.751862C13.7265 0.976906 13.6 1.28213 13.6 1.60039V2.80039H6.40002V1.60039C6.40002 1.28213 6.2736 0.976906 6.04855 0.751862C5.82351 0.526819 5.51828 0.400391 5.20002 0.400391ZM5.20002 6.40039C4.88176 6.40039 4.57654 6.52682 4.3515 6.75186C4.12645 6.97691 4.00002 7.28213 4.00002 7.60039C4.00002 7.91865 4.12645 8.22388 4.3515 8.44892C4.57654 8.67396 4.88176 8.80039 5.20002 8.80039H14.8C15.1183 8.80039 15.4235 8.67396 15.6486 8.44892C15.8736 8.22388 16 7.91865 16 7.60039C16 7.28213 15.8736 6.97691 15.6486 6.75186C15.4235 6.52682 15.1183 6.40039 14.8 6.40039H5.20002Z" fill="#FF5833"/>
                        </svg>
                        {singePost?.data[0].booking_date} - {singePost?.data[0].end_date}
                    </p>
                    {
                    singePost?.data[0].post && 
                      <LeaseMap onMapClicked={onMapClicked} post={singePost?.data[0]} fromPage={fromPage} containerStyle={{position: 'relative', width: '100%', height: '240px'}} style={{display: "flex", position: 'relative', width: '100%', height: "240px"}}/>
                    }

                    <p className="cgray fs14 mb-3 mt-2">
                        <svg width="18" height="21" style={{marginRight:"10px", marginLeft:"10px"}} viewBox="0 0 18 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M3.06022 2.85984C4.6356 1.28446 6.77228 0.399414 9.00021 0.399414C11.2281 0.399414 13.3648 1.28446 14.9402 2.85984C16.5156 4.43523 17.4006 6.57191 17.4006 8.79984C17.4006 11.0278 16.5156 13.1645 14.9402 14.7398L9.00021 20.6798L3.06022 14.7398C2.28011 13.9598 1.6613 13.0338 1.2391 12.0146C0.81691 10.9954 0.599609 9.90302 0.599609 8.79984C0.599609 7.69667 0.81691 6.6043 1.2391 5.5851C1.6613 4.56591 2.28011 3.63987 3.06022 2.85984ZM9.00021 11.1998C9.63673 11.1998 10.2472 10.947 10.6973 10.4969C11.1474 10.0468 11.4002 9.43636 11.4002 8.79984C11.4002 8.16332 11.1474 7.55287 10.6973 7.10279C10.2472 6.6527 9.63673 6.39984 9.00021 6.39984C8.3637 6.39984 7.75325 6.6527 7.30316 7.10279C6.85307 7.55287 6.60021 8.16332 6.60021 8.79984C6.60021 9.43636 6.85307 10.0468 7.30316 10.4969C7.75325 10.947 8.3637 11.1998 9.00021 11.1998Z" fill="#AAAAAA"/>
                        </svg>
                        {singePost?.data[0].post.location}
                    </p>

                    <div className="lease-divider mt-4">
                        <span></span>
                    </div>

                    <div className="lease-single-owner-div">
                        <div className="d-flex justify-content-center align-items-center cur-pointer" onClick={onClickLessor}>
                        <div className="ls-owner-img-div me-3">
                            <img src={singePost?.data[0].user.avatar ? singePost?.data[0].user.avatar : defaultAvatar} className="imgenclose" alt="avatar" />
                        </div>
                        <div>
                            <p className="fw700 cblack mb-0">{singePost?.data[0].lessor.name}</p>
                            <p className="cgray fs14 mb-0">
                            <FormattedMessage 
                                id="owner.text"
                                defaultMessage="Owner"
                                description="Owner"
                            />
                            </p>
                        </div>
                        </div>
                        <span className="chat-icon-div" onClick={() => setShowModal(true)}>
                        <svg width="22" height="20" viewBox="0 0 26 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M25.8 12C25.8 18.1856 20.0688 23.2 13 23.2C10.7281 23.2096 8.4873 22.6718 6.46721 21.632L0.200012 23.2L2.34081 18.2032C0.988812 16.4272 0.200012 14.2944 0.200012 12C0.200012 5.81445 5.93121 0.800049 13 0.800049C20.0688 0.800049 25.8 5.81445 25.8 12ZM8.20001 10.4H5.00001V13.6H8.20001V10.4ZM21 10.4H17.8V13.6H21V10.4ZM11.4 10.4H14.6V13.6H11.4V10.4Z" fill="white"/>
                        </svg>
                        </span>
                    </div>

                    </div>
                </div>
                <div className="col-12 col-lg-5">
                    <div className="div-mtop"></div>
                    <div className="term-outer-div">
                    <p className="cgray fs14 mb-4 d-flex justify-content-between">
                        {singePost?.data[0].invoice.payment_date}
                        <span>INV-{singePost?.data[0].invoice.id}</span>
                    </p>

                    <p className={`fw700 fs18 cblack ${showDetails ? 'd-none' : 'd-block'}`}>
                    <FormattedMessage 
                        id="leaseterm.text"
                        defaultMessage="Lease Term"
                        description="Lease Term"
                    /> - {translateTerm(singePost?.data[0].lease_term)}</p>

                    <div className={`${showDetails ? 'd-block' : 'd-none'}`}>
                        <p className="fw700 cblack fs18 mb-3 d-flex justify-content-between">
                        <FormattedMessage 
                            id="leaseterm.text"
                            defaultMessage="Lease Term"
                            description="Lease Term"
                        />
                        <span>
                            <FormattedMessage 
                            id="price.text"
                            defaultMessage="Price"
                            description="Price"
                            />
                        </span>
                        </p>
                        <p className="cblack fs14 mb-3 d-flex justify-content-between">
                        {translateTerm(singePost?.data[0].lease_term)}
                        <span className="fw700">SAR {pricePerTerm}</span>
                        </p>
                        <p className="fw700 cblack fs16 mb-3 d-flex justify-content-between">
                        <FormattedMessage 
                            id="subtotal.text"
                            defaultMessage="Sub Total"
                            description="Sub Total"
                        />
                        <span>SAR {singePost?.data[0].invoice.subtotal}</span>
                        </p>
                        <p className="fw700 cblack fs16 mb-3 d-flex justify-content-between">
                        <FormattedMessage 
                            id="deposit.text"
                            defaultMessage="Deposit"
                            description="Deposit"
                        />
                        <span>{singePost?.data[0].post_lease_term.deposit}</span>
                        </p>
                        <p className="fw700 cblack fs16 mb-3 d-flex justify-content-between">
                        <FormattedMessage 
                            id="discount.text"
                            defaultMessage="Discount"
                            description="Discount"
                        />
                        <span>{singePost?.data[0].invoice.discount_amount}</span>
                        </p>
                        <p className="fw700 cblack fs16 mb-3 d-flex justify-content-between">
                        <FormattedMessage 
                            id="extrafees.text"
                            defaultMessage="Extra Fees"
                            description="Extra Fees"
                        />
                        <span>0</span>
                        </p>
                        <p className="fw700 cblack fs16 mb-3 d-flex justify-content-between">
                        <FormattedMessage 
                            id="vat.text"
                            defaultMessage="VAT"
                            description="VAT"
                        />
                        <span>{singePost?.data[0].invoice.vat_price}</span>
                        </p>
                    </div>


                    <div className="divider-dashed mb-4"></div>

                    <p className="fw700 cblack fs20 mb-4 d-flex justify-content-between">
                        <FormattedMessage 
                        id="total.text"
                        defaultMessage="Total"
                        description="Total"
                        />
                        <span>SAR {singePost?.data[0].invoice.total}</span>
                    </p>

                    <p className="cred fw600 fs16 text-border-orange cur-pointer " onClick={() => setShowDetails(!showDetails)}>
                        <FormattedMessage 
                        id="showdetails.text"
                        defaultMessage="Show details"
                        description="Show details"
                        />
                    </p>
                    </div>

                    {
                        singePost?.data[0] && 
                        <div className="cancellable-text">
                            <FormattedMessage 
                            id="cancellable_before.text"
                            defaultMessage="Cancellable before"
                            description="Cancellable before"
                            />
                            {" "}
                            {singePost?.data[0].cancellable_before_at}
                        </div>
                    }
                    

                    {singePost?.data[0].refund === null &&  singePost?.data[0] ?(
                    <div className="d-flex mt-5 mb-5 div-button-active">
                        {/* RATING BUTTON */}
                        {postReviews && postReviews.data.length !== 0 ? (
                        <button className="btn btn-rated-already w-50 me-3">
                            <svg width="22" height="22" style={{marginRight:"10px", marginTop:"-3px"}} viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3 15.75C3 15.4545 3.0582 15.1619 3.17127 14.889C3.28434 14.616 3.45008 14.3679 3.65901 14.159C3.86794 13.9501 4.11598 13.7843 4.38896 13.6713C4.66194 13.5582 4.95453 13.5 5.25 13.5C5.54547 13.5 5.83806 13.5582 6.11104 13.6713C6.38402 13.7843 6.63206 13.9501 6.84099 14.159C7.04992 14.3679 7.21566 14.616 7.32873 14.889C7.4418 15.1619 7.5 15.4545 7.5 15.75V24.75C7.5 25.3467 7.26295 25.919 6.84099 26.341C6.41903 26.7629 5.84674 27 5.25 27C4.65326 27 4.08097 26.7629 3.65901 26.341C3.23705 25.919 3 25.3467 3 24.75V15.75ZM9 15.4995V23.6445C8.99974 24.202 9.15483 24.7485 9.44787 25.2228C9.74091 25.6971 10.1603 26.0803 10.659 26.3295L10.734 26.367C11.5663 26.783 12.484 26.9997 13.4145 27H21.5385C22.2323 27.0003 22.9047 26.7601 23.4413 26.3203C23.9779 25.8805 24.3455 25.2683 24.4815 24.588L26.2815 15.588C26.3685 15.1528 26.3578 14.7037 26.2503 14.2732C26.1428 13.8426 25.9411 13.4412 25.6597 13.098C25.3783 12.7548 25.0243 12.4783 24.6232 12.2884C24.2221 12.0986 23.7838 12 23.34 12H18V6C18 5.20435 17.6839 4.44129 17.1213 3.87868C16.5587 3.31607 15.7956 3 15 3C14.6022 3 14.2206 3.15804 13.9393 3.43934C13.658 3.72064 13.5 4.10218 13.5 4.5V5.5005C13.5 6.79872 13.0789 8.06192 12.3 9.1005L10.2 11.8995C9.42107 12.9381 9 14.2013 9 15.4995Z" fill="white"/>
                            </svg>
                            <FormattedMessage 
                            id="ratedlaready.text"
                            defaultMessage="Rated already!"
                            description="Rated already!"
                            />
                        </button>
                        ) 
                        // : 
                        // singePost?.data[0].from !== "active" ? (
                        // <button className="btn btn-post-review w-50 me-3" onClick={() => setShowModalReview(true)}>
                        //     <svg width="15" height="15" style={{marginRight:"10px"}} viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                        //     <path fillRule="evenodd" clipRule="evenodd" d="M11 0.5C11.3978 0.5 11.7794 0.658035 12.0607 0.93934C12.342 1.22064 12.5 1.60218 12.5 2V9.5H20C20.3978 9.5 20.7794 9.65804 21.0607 9.93934C21.342 10.2206 21.5 10.6022 21.5 11C21.5 11.3978 21.342 11.7794 21.0607 12.0607C20.7794 12.342 20.3978 12.5 20 12.5H12.5V20C12.5 20.3978 12.342 20.7794 12.0607 21.0607C11.7794 21.342 11.3978 21.5 11 21.5C10.6022 21.5 10.2206 21.342 9.93934 21.0607C9.65804 20.7794 9.5 20.3978 9.5 20V12.5H2C1.60218 12.5 1.22064 12.342 0.93934 12.0607C0.658035 11.7794 0.5 11.3978 0.5 11C0.5 10.6022 0.658035 10.2206 0.93934 9.93934C1.22064 9.65804 1.60218 9.5 2 9.5H9.5V2C9.5 1.60218 9.65804 1.22064 9.93934 0.93934C10.2206 0.658035 10.6022 0.5 11 0.5Z" fill="white"/>
                        //     </svg>
                        //     <FormattedMessage 
                        //     id="postreview.text"
                        //     defaultMessage="Post a review"
                        //     description="Post a review"
                        //     />
                        // </button>
                        // ) 
                        : ""}
                        
                        {singePost?.data[0].is_cancellable === "true" && singePost?.data[0].cancel_status === null && singePost?.data[0].deleted_at === null ? (
                        <button className="btn btn-cancel-lease w-50 cred" onClick={onClickCancel}>
                            <FormattedMessage 
                            id="cancellease.text"
                            defaultMessage="Cancel Lease"
                            description="Cancel Lease"
                            />
                        </button>
                        ) : singePost?.data[0].cancel_status === null && singePost?.data[0].deleted_at !== null ? (
                        <p className="cred fw600 fs16">
                            <FormattedMessage 
                            id="pending_cancel.text"
                            defaultMessage="You have requested a booking cancellation. Waiting for approval"
                            description="You have requested a booking cancellation. Waiting for approval"
                            />
                        </p>
                        ) : singePost?.data[0].cancel_status === "1" && singePost?.data[0].deleted_at !== null ? (
                        <p className="cred fw600 fs16">
                            <FormattedMessage 
                            id="approved_booked_cancel.text"
                            defaultMessage="Booking cancellation approved"
                            description="Booking cancellation approved"
                            />
                        </p>
                        ) : singePost?.data[0].cancel_status === "0" && singePost?.data[0].deleted_at === null ? (
                        <p className="cred fw600 fs16">
                            <FormattedMessage 
                            id="rejected_booked_cancel.text"
                            defaultMessage="Booking cancellation rejected"
                            description="Booking cancellation rejected"
                            />
                        </p>
                        ) : ""}
                    </div>
                    ) : 
                    singePost?.data[0] ? (
                    <button className="btn btn-cancel-lease w-50 cred mt-5 mb-5">
                        <FormattedMessage 
                        id="cancelled.text"
                        defaultMessage="Cancelled"
                        description="Cancelled"
                        />
                    </button>
                    // <div> </div>
                    )
                : ""}

                    


                    {/* Modal review */}
                    <Modal className="" show={showModalReview} onHide={handleCloseModalReview}>
                    <Modal.Body>
                        <div className="modal-review-div">
                        <div className="modal-review-img-div">
                            <img src={singePost?.data[0].user.avatar ? singePost?.data[0].user.avatar : defaultAvatar} className="imgenclose" alt="avatar" />
                        </div>
                        <p className="mb-5 fw700 fs20 cblack text-center mt-4">{singePost?.data[0].lessor?.name}</p>

                        <div className="lease-divider-light">
                            <span></span>
                        </div>

                        <div className="review-rate-div">
                            <p className="fs16 cblack mb-4 text-center mt-5">Rate overall performance</p>
                            <p className="mb-4 text-center review-stars mb-5">
                            <svg width="46" height="42" className={`${starRateClick && starRateClick >= 1 ? "star-svg" : ""}`} onClick={() => onClickStarRate(1)} viewBox="0 0 86 82" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M38.3464 4.17185C39.6964 0.0273516 45.5599 0.0273516 46.9054 4.17185L53.7409 25.2049C54.0351 26.107 54.6069 26.8932 55.3747 27.4508C56.1425 28.0085 57.067 28.3092 58.0159 28.3099H80.1334C84.4939 28.3099 86.3029 33.8899 82.7794 36.4549L64.8874 49.4509C64.1189 50.0096 63.5469 50.7973 63.2534 51.701C62.96 52.6048 62.9602 53.5782 63.2539 54.4819L70.0849 75.5149C71.4349 79.6639 66.6874 83.1109 63.1639 80.5459L45.2719 67.5499C44.5029 66.9908 43.5766 66.6897 42.6259 66.6897C41.6752 66.6897 40.7489 66.9908 39.9799 67.5499L22.0879 80.5459C18.5644 83.1109 13.8169 79.6594 15.1669 75.5149L21.9979 54.4819C22.2917 53.5782 22.2918 52.6048 21.9984 51.701C21.705 50.7973 21.133 50.0096 20.3644 49.4509L2.47242 36.4549C-1.05558 33.8899 0.762418 28.3099 5.11842 28.3099H27.2314C28.1811 28.3101 29.1066 28.0099 29.8753 27.4521C30.644 26.8944 31.2165 26.1078 31.5109 25.2049L38.3464 4.17185V4.17185Z" stroke="#AAAAAA" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                            <svg width="46" height="42" className={`${starRateClick && starRateClick >= 2 ? "star-svg" : ""}`} onClick={() => onClickStarRate(2)} viewBox="0 0 86 82" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M38.3464 4.17185C39.6964 0.0273516 45.5599 0.0273516 46.9054 4.17185L53.7409 25.2049C54.0351 26.107 54.6069 26.8932 55.3747 27.4508C56.1425 28.0085 57.067 28.3092 58.0159 28.3099H80.1334C84.4939 28.3099 86.3029 33.8899 82.7794 36.4549L64.8874 49.4509C64.1189 50.0096 63.5469 50.7973 63.2534 51.701C62.96 52.6048 62.9602 53.5782 63.2539 54.4819L70.0849 75.5149C71.4349 79.6639 66.6874 83.1109 63.1639 80.5459L45.2719 67.5499C44.5029 66.9908 43.5766 66.6897 42.6259 66.6897C41.6752 66.6897 40.7489 66.9908 39.9799 67.5499L22.0879 80.5459C18.5644 83.1109 13.8169 79.6594 15.1669 75.5149L21.9979 54.4819C22.2917 53.5782 22.2918 52.6048 21.9984 51.701C21.705 50.7973 21.133 50.0096 20.3644 49.4509L2.47242 36.4549C-1.05558 33.8899 0.762418 28.3099 5.11842 28.3099H27.2314C28.1811 28.3101 29.1066 28.0099 29.8753 27.4521C30.644 26.8944 31.2165 26.1078 31.5109 25.2049L38.3464 4.17185V4.17185Z" stroke="#AAAAAA" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                            <svg width="46" height="42" className={`${starRateClick && starRateClick >= 3 ? "star-svg" : ""}`} onClick={() => onClickStarRate(3)} viewBox="0 0 86 82" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M38.3464 4.17185C39.6964 0.0273516 45.5599 0.0273516 46.9054 4.17185L53.7409 25.2049C54.0351 26.107 54.6069 26.8932 55.3747 27.4508C56.1425 28.0085 57.067 28.3092 58.0159 28.3099H80.1334C84.4939 28.3099 86.3029 33.8899 82.7794 36.4549L64.8874 49.4509C64.1189 50.0096 63.5469 50.7973 63.2534 51.701C62.96 52.6048 62.9602 53.5782 63.2539 54.4819L70.0849 75.5149C71.4349 79.6639 66.6874 83.1109 63.1639 80.5459L45.2719 67.5499C44.5029 66.9908 43.5766 66.6897 42.6259 66.6897C41.6752 66.6897 40.7489 66.9908 39.9799 67.5499L22.0879 80.5459C18.5644 83.1109 13.8169 79.6594 15.1669 75.5149L21.9979 54.4819C22.2917 53.5782 22.2918 52.6048 21.9984 51.701C21.705 50.7973 21.133 50.0096 20.3644 49.4509L2.47242 36.4549C-1.05558 33.8899 0.762418 28.3099 5.11842 28.3099H27.2314C28.1811 28.3101 29.1066 28.0099 29.8753 27.4521C30.644 26.8944 31.2165 26.1078 31.5109 25.2049L38.3464 4.17185V4.17185Z" stroke="#AAAAAA" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                            <svg width="46" height="42" className={`${starRateClick && starRateClick >= 4 ? "star-svg" : ""}`} onClick={() => onClickStarRate(4)} viewBox="0 0 86 82" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M38.3464 4.17185C39.6964 0.0273516 45.5599 0.0273516 46.9054 4.17185L53.7409 25.2049C54.0351 26.107 54.6069 26.8932 55.3747 27.4508C56.1425 28.0085 57.067 28.3092 58.0159 28.3099H80.1334C84.4939 28.3099 86.3029 33.8899 82.7794 36.4549L64.8874 49.4509C64.1189 50.0096 63.5469 50.7973 63.2534 51.701C62.96 52.6048 62.9602 53.5782 63.2539 54.4819L70.0849 75.5149C71.4349 79.6639 66.6874 83.1109 63.1639 80.5459L45.2719 67.5499C44.5029 66.9908 43.5766 66.6897 42.6259 66.6897C41.6752 66.6897 40.7489 66.9908 39.9799 67.5499L22.0879 80.5459C18.5644 83.1109 13.8169 79.6594 15.1669 75.5149L21.9979 54.4819C22.2917 53.5782 22.2918 52.6048 21.9984 51.701C21.705 50.7973 21.133 50.0096 20.3644 49.4509L2.47242 36.4549C-1.05558 33.8899 0.762418 28.3099 5.11842 28.3099H27.2314C28.1811 28.3101 29.1066 28.0099 29.8753 27.4521C30.644 26.8944 31.2165 26.1078 31.5109 25.2049L38.3464 4.17185V4.17185Z" stroke="#AAAAAA" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                            <svg width="46" height="42" className={`${starRateClick && starRateClick >= 5 ? "star-svg" : ""}`} onClick={() => onClickStarRate(5)} viewBox="0 0 86 82" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M38.3464 4.17185C39.6964 0.0273516 45.5599 0.0273516 46.9054 4.17185L53.7409 25.2049C54.0351 26.107 54.6069 26.8932 55.3747 27.4508C56.1425 28.0085 57.067 28.3092 58.0159 28.3099H80.1334C84.4939 28.3099 86.3029 33.8899 82.7794 36.4549L64.8874 49.4509C64.1189 50.0096 63.5469 50.7973 63.2534 51.701C62.96 52.6048 62.9602 53.5782 63.2539 54.4819L70.0849 75.5149C71.4349 79.6639 66.6874 83.1109 63.1639 80.5459L45.2719 67.5499C44.5029 66.9908 43.5766 66.6897 42.6259 66.6897C41.6752 66.6897 40.7489 66.9908 39.9799 67.5499L22.0879 80.5459C18.5644 83.1109 13.8169 79.6594 15.1669 75.5149L21.9979 54.4819C22.2917 53.5782 22.2918 52.6048 21.9984 51.701C21.705 50.7973 21.133 50.0096 20.3644 49.4509L2.47242 36.4549C-1.05558 33.8899 0.762418 28.3099 5.11842 28.3099H27.2314C28.1811 28.3101 29.1066 28.0099 29.8753 27.4521C30.644 26.8944 31.2165 26.1078 31.5109 25.2049L38.3464 4.17185V4.17185Z" stroke="#AAAAAA" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                            </p>

                            <textarea className="form-control mb-4" placeholder="Write a review (optional)" rows="2" value={rateComment} onChange={(e) => setRateComment(e.target.value)}></textarea>
                            <button className="btn btn-payment w-100 mt-3 mb-5" onClick={onSubmitRate}>Submit</button>
                        </div>


                        </div>
                    </Modal.Body>
                    </Modal>



                    {/* map modal */}
                    {/* <Modal className="modalChat" show={mapShowModal} onHide={handleCloseMapModal}>
                    <Modal.Body className="modal-postshipment-div modal-map">
                                
                        <div className="map-modal-div">
                        <LeaseMap post={singePost?.data[0]} fromPage={fromPage}/>
                        </div>

                    </Modal.Body>
                    </Modal> */}


                    {/* Modal chat */}
                    <Modal className="modalChat" show={showModal} onHide={handleCloseModal}>
                    <Modal.Body>
                        <div className="lease-single-owner-div mb-4">
                        <div className="d-flex justify-content-center align-items-center">
                            <div className="ls-owner-img-div me-3">
                            <img src={singePost?.data[0].user.avatar ? singePost?.data[0].user.avatar : defaultAvatar} className="imgenclose" alt="avatar" />
                            </div>
                            <div>
                            <p className="fw700 cblack mb-0">{singePost?.data[0] && singePost?.data[0].lessor.name}</p>
                            <p className="cgray fs14 mb-0">
                                <FormattedMessage 
                                id="owner.text"
                                defaultMessage="Owner"
                                description="Owner"
                                />
                            </p>
                            </div>
                        </div>
                        <a href="javascript:void(0);" onClick={() => setShowModal(false)}>
                            <svg width="16" height="16" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M0.868483 0.868832C1.16853 0.568879 1.57542 0.400374 1.99968 0.400374C2.42395 0.400374 2.83084 0.568879 3.13088 0.868832L9.99968 7.73763L16.8685 0.868832C17.0161 0.716016 17.1926 0.594124 17.3878 0.51027C17.583 0.426416 17.793 0.382278 18.0054 0.380432C18.2179 0.378585 18.4286 0.419068 18.6252 0.499517C18.8218 0.579967 19.0005 0.698772 19.1507 0.849C19.3009 0.999228 19.4197 1.17787 19.5002 1.37451C19.5806 1.57114 19.6211 1.78183 19.6193 1.99427C19.6174 2.20672 19.5733 2.41667 19.4894 2.61188C19.4056 2.80709 19.2837 2.98364 19.1309 3.13123L12.2621 10L19.1309 16.8688C19.4223 17.1706 19.5836 17.5748 19.58 17.9943C19.5763 18.4138 19.408 18.8151 19.1114 19.1117C18.8147 19.4084 18.4134 19.5767 17.9939 19.5803C17.5744 19.584 17.1702 19.4227 16.8685 19.1312L9.99968 12.2624L3.13088 19.1312C2.82912 19.4227 2.42496 19.584 2.00544 19.5803C1.58593 19.5767 1.18463 19.4084 0.887973 19.1117C0.59132 18.8151 0.423049 18.4138 0.419404 17.9943C0.415758 17.5748 0.57703 17.1706 0.868483 16.8688L7.73728 10L0.868483 3.13123C0.568529 2.83119 0.400024 2.42429 0.400024 2.00003C0.400024 1.57577 0.568529 1.16888 0.868483 0.868832Z" fill="#FF5833"/>
                            </svg>
                        </a>
                        </div>

                        {!chosenChatLoading ? (
                        <textarea className="form-control textarea-chat" placeholder="Write a custom message" rows="5" defaultValue={chosenChatMsg} onChange={(e) => setChosenChatMsg(e.target.value)}></textarea>
                        ) : (
                        <div className="loader"></div>
                        )}

                        {/* <div className="mt-4">
                        <div 
                            className={`chat-auto-msg ${chosenChatMsg === "Can I see more photos?" ? 'active' : ''}`}
                            onClick={() => changeMsg("Can I see more photos?")}
                        >
                            <FormattedMessage 
                            id="caniseemore.text"
                            defaultMessage="Can I see more photos?"
                            description="Can I see more photos?"
                            />
                        </div>
                        <div
                            className={`chat-auto-msg ${chosenChatMsg === "Can you provide me a map location?" ? 'active' : ''}`}
                            onClick={() => changeMsg("Can you provide me a map location?")}
                        >
                            <FormattedMessage 
                            id="canprovmap.text"
                            defaultMessage="Can you provide me a map location?"
                            description="Can you provide me a map location?"
                            />
                        </div>
                        <div
                            className={`chat-auto-msg ${chosenChatMsg === "I want to make a request" ? 'active' : ''}`}
                            onClick={() => changeMsg("I want to make a request")}
                        >
                            <FormattedMessage 
                            id="makereq.text"
                            defaultMessage="I want to make a request"
                            description="I want to make a request"
                            />
                        </div>
                        </div> */}

                        <button className="btn w-100 btn-chat-submit" disabled={chosenChatMsg === "" ? true : false} onClick={onSubmitChat}>
                        <FormattedMessage 
                            id="submit.text"
                            defaultMessage="Submit"
                            description="Submit"
                        />
                        </button>
                    </Modal.Body>
                    </Modal>



                </div>
                </div>
            </div>
        </div>
    )
}

export default MyLeaseSingleActive;