import React, { useState, useEffect, useContext, useRef } from "react";
import { DateTime } from "luxon";
import Swal from 'sweetalert2';
import axios from "axios";
import {FormattedMessage, useIntl } from 'react-intl';
import { FirebaseContext } from "../../contexts/firebase.context";
import { RouteContext } from "../../contexts/route.context";

const ChatLease = () => {
  const intl = useIntl();
  const bottomRef = useRef(null);
  const { baseURL, apiHeaders, getMe, me, getChatList, chatList, getUserChatList,
    userChatList, getChatUserDetails, chatUserDetails, } = useContext(RouteContext);
  const { database } = useContext(FirebaseContext);
  const [chatIds, setChatIds] = useState([]);
  const [listUser, setListUser] = useState([]);
  const [chosenChat, setChosenChat] = useState();
  const [chatMsg, setChatMsg] = useState("");
  const [uploadPhotoChat, setUploadPhotoChat] = useState();
  const messagesEndRef = useRef(null);

  useEffect(() => {
    getMe();
  }, []);

  useEffect(() => {
    if(me) {
      getUserChatList(me.id);
    }
  }, [me]);

  useEffect(() => {
    if(userChatList) {
      let arrayIds = new Array();

      userChatList.map((e) => {
        arrayIds.push(parseInt(e.user_id));
      });

      const arrayIdsFil = arrayIds.filter(function (value) {
        return !Number.isNaN(value);
      });
      setChatIds(arrayIdsFil);
    }
    

  }, [userChatList]);

  useEffect(() => {
    if(chatIds.length !== 0) {
      getChatUserDetails(chatIds);
    }
    
  }, [chatIds]);

  useEffect(() => {
    if(userChatList && chatUserDetails) {
      let listArr = new Array();
      chatUserDetails.data.map((e) => {
        let lstMsg = userChatList.filter((usc) => usc.user_id === e.id);
        e.last_message = lstMsg[0].last_message;
        e.time = lstMsg[0].time;
        listArr.push(e)
      });

      setListUser(listArr);
      console.log(chatUserDetails);
    }

  }, [userChatList, chatUserDetails]);

  const onClickChat = (chat) => {
    setChosenChat(chat);
    getChatList(me.id, chat.id);
    bottomRef.current?.scrollIntoView({behavior: 'smooth', block: 'end', inline: 'start'});
  }

  // upload image
  const hiddenFileInputPhotoChat = useRef(null);
  const handleClickPhotoChat = event => {
    hiddenFileInputPhotoChat.current.click();
  };
  const handleChangePhotoChat = event => {
    const fileUploaded = event.target.files[0];
    setUploadPhotoChat(fileUploaded);
    setChatMsg(fileUploaded.name);
  };

  function updateLastMsgUser() {
    let refUsers = "users/" + me.id;
    let usersFilteredRef = database
      .ref(refUsers)
      .orderByChild("user_id")
      .equalTo(chosenChat.id);
    let userKey;

    usersFilteredRef.once("value", (snapshot) => {
      const usersObj = (snapshot && snapshot.val()) || {};

      const usersArr =
        (usersObj &&
          Object.keys(usersObj) &&
          Object.keys(usersObj).length &&
          Object.keys(usersObj).map((key) => {
            usersObj[key].key = key;
            // delete usersObj[key].children;
            return usersObj[key];
          })) ||
        [];

      userKey = usersArr[0].key;

      let refUserFinal = refUsers + "/" + userKey;
  
      database
        .ref(refUserFinal)
        .update({
          date: DateTime.now().toFormat('MMMM dd, yyyy'),
          last_message: chatMsg,
          time: new Date().toLocaleTimeString().replace(/(.*)\D\d+/, '$1'),
        });
    });
    
  }

  // upload img to api then success return img url
  const uploadImageToFirebaseGetUrl = async (chosenChat) => {
    const formData = new FormData();

    const query = `
      mutation($input: uploadImageChatInput!){
        uploadImageChat(input:$input){
          file_name
          url
        }
      }
    `;

    const input = {
      image: null,
    };

    const operations = JSON.stringify({ query, variables: { input } });
    formData.append("operations", operations);

    const map = {
        "0": ["variables.input.image"],
    };
    formData.append("map", JSON.stringify(map));

    const file = uploadPhotoChat? uploadPhotoChat : null;
    formData.append("0", file);

    let result = await axios({
        url: baseURL,
        method: "post",
        headers: apiHeaders,
        data: formData
    });

    console.log(result)

    if(result.data.data) {
      let urlImg = result.data.data.uploadImageChat.url;

      let chatDetails = {
        attachment: urlImg,
        customer_id: chosenChat.id,
        date: DateTime.now().toFormat('MMMM dd, yyyy'),
        message: chatMsg,
        sender_id: me.id,
        time: new Date().toLocaleTimeString().replace(/(.*)\D\d+/, '$1'),
      }
      
      let refChat = "room/fb_user" + me.id + "_fb_user" + chosenChat.id;
      database.ref(refChat).push(chatDetails);
      updateLastMsgUser();
      setChatMsg("");
      setUploadPhotoChat();

      getChatList(me.id, chosenChat.id);
      bottomRef.current?.scrollIntoView({behavior: 'smooth', block: 'end', inline: 'start'});
    }
    else {

      if(result.data.errors[0].extensions.category === "validation") {
        let errorMsg = result.data.errors[0].extensions.validation;
        let errorMsgArray = new Array();
        Object.keys(errorMsg).map((key) => {
          errorMsgArray.push(errorMsg[key][0]);
        });


        Swal.fire({
          title: 'Uploading Image Failed',
          text: errorMsgArray.toString(),
          icon: 'warning',
          confirmButtonColor: '#ff9035',
        }).then((e) => {
          window.location.reload();
        });
      }
      else {
        Swal.fire({
          title: 'Uploading Image Failed',
          text: result.data.errors[0].message,
          icon: 'warning',
          confirmButtonColor: '#ff9035',
        }).then((e) => {
          window.location.reload();
        });
      }

    }


  }

  const onSubmitChat = () => {
    // if uploading chat img
    console.log(chatMsg)
    if(uploadPhotoChat) {
      uploadImageToFirebaseGetUrl(chosenChat);
    }
    else {
      if(chatMsg !== "") {
        let chatDetails = {
          attachment: "",
          customer_id: chosenChat.id,
          date: DateTime.now().toFormat('MMMM dd, yyyy'),
          message: chatMsg,
          sender_id: me.id,
          time: new Date().toLocaleTimeString().replace(/(.*)\D\d+/, '$1'),
        }
        
        let refChat = "room/fb_user" + me.id + "_fb_user" + chosenChat.id;
        database.ref(refChat).push(chatDetails);
        updateLastMsgUser();
        setChatMsg("");
      }
    }
    getChatList(me?.id, chosenChat?.id);
    bottomRef.current?.scrollIntoView({behavior: 'smooth', block: 'end', inline: 'start'});
  }

  // if hit enter key
  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      // if uploading chat img
      if(uploadPhotoChat) {
        uploadImageToFirebaseGetUrl(chosenChat);
      }
      else {
        if(chatMsg !== "") {
          let chatDetails = {
            attachment: "",
            customer_id: chosenChat.id,
            date: DateTime.now().toFormat('MMMM dd, yyyy'),
            message: chatMsg,
            sender_id: me.id,
            time: new Date().toLocaleTimeString().replace(/(.*)\D\d+/, '$1'),
          }
          
          let refChat = "room/fb_user" + me.id + "_fb_user" + chosenChat.id;
          database.ref(refChat).push(chatDetails);
          updateLastMsgUser();
          setChatMsg("");
        }
      }
      
      getChatList(me.id, chosenChat.id);
      bottomRef.current?.scrollIntoView({behavior: 'smooth', block: 'end', inline: 'start'});
    }
  }


  return (
    <div>
      <div className="container-fluid leases-single-div">
        {listUser.length !== 0 ? (
          <div className="row">
            <div className="col-12 px-0">
              
              <div className="chat-wrapper-div">
                <div className="left-chat">
                  {/* <div className="left-top">
                    <div className="search-chat-div">
                      <svg width="18" height="18" style={{position:"absolute", top:"9px", left:"16px"}} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M9 3.00017C7.4087 3.00017 5.88258 3.63231 4.75736 4.75753C3.63214 5.88275 3 7.40887 3 9.00017C3 10.5915 3.63214 12.1176 4.75736 13.2428C5.88258 14.368 7.4087 15.0002 9 15.0002C10.5913 15.0002 12.1174 14.368 13.2426 13.2428C14.3679 12.1176 15 10.5915 15 9.00017C15 7.40887 14.3679 5.88275 13.2426 4.75753C12.1174 3.63231 10.5913 3.00017 9 3.00017ZM1.70192e-07 9.00017C-0.000180585 7.58373 0.333964 6.18724 0.975257 4.92428C1.61655 3.66133 2.54688 2.56757 3.69059 1.73196C4.8343 0.896353 6.15909 0.342491 7.55722 0.115422C8.95534 -0.111646 10.3873 -0.0055103 11.7367 0.425198C13.0861 0.855907 14.3147 1.59903 15.3227 2.59412C16.3308 3.58921 17.0897 4.80817 17.5378 6.15187C17.9859 7.49557 18.1105 8.92606 17.9015 10.327C17.6925 11.7279 17.1558 13.0598 16.335 14.2142L23.5605 21.4397C23.8337 21.7226 23.9849 22.1015 23.9815 22.4948C23.9781 22.8881 23.8203 23.2643 23.5422 23.5424C23.2641 23.8205 22.8879 23.9783 22.4946 23.9817C22.1013 23.9851 21.7224 23.8339 21.4395 23.5607L14.2155 16.3367C12.869 17.2941 11.285 17.8624 9.63701 17.9794C7.98901 18.0963 6.34061 17.7573 4.87245 16.9996C3.40429 16.2419 2.17303 15.0947 1.31359 13.6837C0.45414 12.2727 -0.000321076 10.6523 1.70192e-07 9.00017Z" fill="#AAAAAA"/>
                      </svg>
                      <input type="text" className="form-control" placeholder="Search Messages" />
                    </div>
                  </div> */}

                  {/* <div className="chat-msg-left-div">
                    <div className="w-25"></div>
                    <div className="w-75">
                      <p className="fs14 fw700 cblack mb-2 d-flex justify-content-between">
                        Ali Ibrahim
                        <span className="fs12 fw400 cgray">10:23 AM</span>
                      </p>

                      <p className="fs14 fw700 cblack mb-0 pe-5 position-relative">
                        Lorem ipsum dolor sit amet, sea ipsum velit elitr te, at accumsan appareat quo. 
                        <span className="new-msg-span ">1</span>
                      </p>
                    </div>
                  </div> */}


                  {listUser && listUser.map((list, index) => (
                    <div key={index} className="chat-msg-left-div" onClick={() => onClickChat(list)}>
                      <div className="chatlist">
                        <p className="fs14 fw700 cblack mb-2 d-flex justify-content-between">
                          {list.name}
                          <span className="fs12 fw400 cgray">{list.time}</span>
                        </p>

                        <p className="fs14 cblack mb-0 pe-5 position-relative">
                          {list.last_message}
                          <span className="new-msg-span seen">
                            <svg width="11" height="7" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path fillRule="evenodd" clipRule="evenodd" d="M13.7064 0.292787C13.8939 0.480314 13.9992 0.734622 13.9992 0.999786C13.9992 1.26495 13.8939 1.51926 13.7064 1.70679L5.70643 9.70679C5.5189 9.89426 5.2646 9.99957 4.99943 9.99957C4.73427 9.99957 4.47996 9.89426 4.29243 9.70679L0.292431 5.70679C0.110272 5.51818 0.00947813 5.26558 0.0117566 5.00339C0.014035 4.74119 0.119204 4.49038 0.304612 4.30497C0.49002 4.11956 0.740832 4.01439 1.00303 4.01211C1.26523 4.00983 1.51783 4.11063 1.70643 4.29279L4.99943 7.58579L12.2924 0.292787C12.48 0.105316 12.7343 0 12.9994 0C13.2646 0 13.5189 0.105316 13.7064 0.292787Z" fill="white"/>
                            </svg>
                          </span>
                        </p>
                      </div>
                    </div>
                  ))}

                  

                </div>
                <div className={`right-chat ${!chosenChat && "border-0"}`}>
                  {chosenChat ? (
                  <>
                    <div className="right-top">
                      <p className="mb-0 fw700 cblack fs18">{chosenChat && chosenChat.name}</p>
                    </div>
                    <div className="msg-content-div">
                    <div id="bottomRef" ref={bottomRef}></div>
                      {chatList && chatList.map((cl, index) => {
                        if(cl.sender_id === me.id) {
                          return (
                          <>
                            <div key={index} className="sender-div">
                              <div>
                                {cl.attachment !== "" ? (
                                  <div className="chat-img">
                                    <img src={cl.attachment} className="imgenclose" />
                                  </div>
                                ) : (
                                  <div className="sender-msg-div">
                                    {cl.message}
                                  </div>
                                )}
                                
                                <span className="fs12 cgray w-100 d-block text-end mt-1">{cl.time} {cl.date}</span>
                              </div>
                            </div>

                            <div className="mt-3">
                              
                            </div>
                          </>
                          )
                        }
                        else {
                          return (
                          <>
                            <div key={index} className="receiver-div">
                              <div>
                                {cl.attachment !== "" ? (
                                  <div className="chat-img">
                                    <img src={cl.attachment} className="imgenclose" />
                                  </div>
                                ) : (
                                  <div className="receiver-msg-div">
                                    {cl.message}
                                  </div>
                                )}
                                
                                <span className="fs12 cgray w-100 d-block mt-1">{cl.time} {cl.date}</span>
                              </div>
                            </div>

                            <div className="mt-3"></div>
                          </>
                          )
                        }
                      
                      })}

                      {/* <div className="receiver-div">
                        <div>
                          <div className="chat-img">
                            <img src="assets/images/chat-vid.png" className="imgenclose" />
                          </div>
                          <div className="receiver-msg-div">
                            Here’s a house tour video!
                          </div>
                          <span className="fs12 cgray w-100 d-block mt-1">10:20 AM</span>
                        </div>
                      </div> */}
                    </div>


                    <div className="right-bottom">
                      <div className="chat-input-div">
                        <input type="text" className="form-control" placeholder={intl.formatMessage({
                          id: 'typemessage.text',
                          defaultMessage: 'Type a message here...',
                          description: 'Type a message here...',
                        })} onKeyDown={handleKeyDown} onChange={(e) => setChatMsg(e.target.value)} value={chatMsg}/>
                        <div className="chat-features-div">
                          
                          <svg width="22" height="18" viewBox="0 0 23 19" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={handleClickPhotoChat}>
                          <path fillRule="evenodd" clipRule="evenodd" d="M3.4002 2.7498C2.68411 2.7498 1.99735 3.03427 1.49101 3.54062C0.984659 4.04696 0.700195 4.73372 0.700195 5.44981V16.2498C0.700195 16.9659 0.984659 17.6526 1.49101 18.159C1.99735 18.6653 2.68411 18.9498 3.4002 18.9498H19.6002C20.3163 18.9498 21.003 18.6653 21.5094 18.159C22.0157 17.6526 22.3002 16.9659 22.3002 16.2498V5.44981C22.3002 4.73372 22.0157 4.04696 21.5094 3.54062C21.003 3.03427 20.3163 2.7498 19.6002 2.7498H17.4591C17.1011 2.74973 16.7578 2.60745 16.5046 2.35425L14.9913 0.840905C14.4851 0.334521 13.7984 0.0499576 13.0824 0.0498047H9.91799C9.20197 0.0499576 8.51533 0.334521 8.00909 0.840905L6.49575 2.35425C6.24263 2.60745 5.89931 2.74973 5.5413 2.7498H3.4002ZM11.5002 14.8998C12.032 14.8998 12.5587 14.7951 13.0501 14.5915C13.5414 14.388 13.9879 14.0897 14.364 13.7136C14.7401 13.3375 15.0384 12.891 15.2419 12.3997C15.4454 11.9083 15.5502 11.3817 15.5502 10.8498C15.5502 10.318 15.4454 9.79131 15.2419 9.29994C15.0384 8.80857 14.7401 8.3621 14.364 7.98602C13.9879 7.60995 13.5414 7.31162 13.0501 7.10809C12.5587 6.90456 12.032 6.79981 11.5002 6.79981C10.4261 6.79981 9.39594 7.2265 8.63641 7.98602C7.87689 8.74555 7.45019 9.77568 7.45019 10.8498C7.45019 11.9239 7.87689 12.9541 8.63641 13.7136C9.39594 14.4731 10.4261 14.8998 11.5002 14.8998Z" fill="#242424"/>
                          </svg>
                          <input
                            type="file"
                            ref={hiddenFileInputPhotoChat}
                            onChange={handleChangePhotoChat}
                            accept="image/*"
                            style={{display: 'none'}}
                          />

                        </div>
                      </div>
                      <button className="btn btn-chat-send ms-2 me-2" onClick={onSubmitChat}>
                        <svg width="25" height="25" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M32.6388 18.8779C32.9871 18.7034 33.28 18.4353 33.4847 18.1037C33.6894 17.7722 33.7979 17.3902 33.7979 17.0005C33.7979 16.6109 33.6894 16.2289 33.4847 15.8974C33.28 15.5658 32.9871 15.2977 32.6388 15.1231L3.23879 0.423146C2.87426 0.240748 2.46452 0.168384 2.05956 0.21488C1.6546 0.261377 1.27192 0.424725 0.958214 0.684998C0.644508 0.945271 0.41333 1.29122 0.292881 1.68063C0.172432 2.07005 0.167915 2.48611 0.279892 2.87805L3.28079 13.378C3.40631 13.8169 3.6714 14.2029 4.03592 14.4776C4.40044 14.7523 4.84454 14.9008 5.30099 14.9005H14.9001C15.457 14.9005 15.9912 15.1218 16.385 15.5156C16.7788 15.9094 17.0001 16.4436 17.0001 17.0005C17.0001 17.5575 16.7788 18.0916 16.385 18.4855C15.9912 18.8793 15.457 19.1005 14.9001 19.1005H5.30099C4.84454 19.1003 4.40044 19.2488 4.03592 19.5235C3.6714 19.7982 3.40631 20.1842 3.28079 20.623L0.28199 31.123C0.169792 31.5149 0.174038 31.9309 0.294201 32.3203C0.414368 32.7097 0.64526 33.0558 0.958725 33.3163C1.27219 33.5768 1.65467 33.7404 2.05953 33.7872C2.4644 33.8341 2.87414 33.7621 3.23879 33.58L32.6388 18.88V18.8779Z" fill="white"/>
                        </svg>
                      </button>
                    </div>
                  </>
                  )
                  :
                  <>
                  </>
                }

                </div>
              </div>

            </div>
          </div>
        ) : (
          <div className="container main-wrap pt-5 mt-4 pb-5">
            <div className="row">
              <div className="col-12">

                {/* <div className="search-chat-div">
                  <svg width="18" height="18" style={{position:"absolute", top:"9px", left:"16px"}} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M9 3.00017C7.4087 3.00017 5.88258 3.63231 4.75736 4.75753C3.63214 5.88275 3 7.40887 3 9.00017C3 10.5915 3.63214 12.1176 4.75736 13.2428C5.88258 14.368 7.4087 15.0002 9 15.0002C10.5913 15.0002 12.1174 14.368 13.2426 13.2428C14.3679 12.1176 15 10.5915 15 9.00017C15 7.40887 14.3679 5.88275 13.2426 4.75753C12.1174 3.63231 10.5913 3.00017 9 3.00017ZM1.70192e-07 9.00017C-0.000180585 7.58373 0.333964 6.18724 0.975257 4.92428C1.61655 3.66133 2.54688 2.56757 3.69059 1.73196C4.8343 0.896353 6.15909 0.342491 7.55722 0.115422C8.95534 -0.111646 10.3873 -0.0055103 11.7367 0.425198C13.0861 0.855907 14.3147 1.59903 15.3227 2.59412C16.3308 3.58921 17.0897 4.80817 17.5378 6.15187C17.9859 7.49557 18.1105 8.92606 17.9015 10.327C17.6925 11.7279 17.1558 13.0598 16.335 14.2142L23.5605 21.4397C23.8337 21.7226 23.9849 22.1015 23.9815 22.4948C23.9781 22.8881 23.8203 23.2643 23.5422 23.5424C23.2641 23.8205 22.8879 23.9783 22.4946 23.9817C22.1013 23.9851 21.7224 23.8339 21.4395 23.5607L14.2155 16.3367C12.869 17.2941 11.285 17.8624 9.63701 17.9794C7.98901 18.0963 6.34061 17.7573 4.87245 16.9996C3.40429 16.2419 2.17303 15.0947 1.31359 13.6837C0.45414 12.2727 -0.000321076 10.6523 1.70192e-07 9.00017Z" fill="#AAAAAA"/>
                  </svg>
                  <input type="text" className="form-control" placeholder="Search Messages" />
                </div> */}

                <div className="row mt-5 pt-5" style={{margin:"0 auto", maxWidth:"550px", width:"100%"}}>
                  <div className="col-12">
                    <div className="text-center">
                      <img src="assets/images/no-lease-img.png" className="img-fluid pt-5 mb-4" />
                      <p className="fs30 fw700 cgray">
                        <FormattedMessage 
                          id="youhavenochats.text"
                          defaultMessage="You have no active chats"
                          description="You have no active chats"
                        />
                      </p>
                    </div>
                  </div>
                </div>
                
              </div>
            </div>
          </div>
        )}
        
      </div>
    </div>
  )
}

export default ChatLease;
