import React, { useState, useEffect, useContext, useRef } from "react";
import axios from "axios";
import Swal from 'sweetalert2';
import {FormattedMessage, useIntl } from 'react-intl';
import { Modal, Button} from 'react-bootstrap';
import { RouteContext } from "../../contexts/route.context";
import { getDifferenceOnDate, } from "../../util/helper";
import applepaylogo from "../../assets/images/Apple_Pay_logo.svg";
import visalogo from "../../assets/images/visa.png";
import masterlogo from "../../assets/images/mc_symbol.svg";
import madalogo from "../../assets/images/Mada_Logo.svg";



const LeasePayment = (props) => {
  const intl = useIntl();
  let paymentDetails = props.paymentDetails;
  const {baseURL, apiHeaders, me, getMe, getDiscount, discount, getAllSettings, allSettings, baseURLPayment, getAllSwiftCodes } = useContext(RouteContext);
  
  const [paymentMethod, setPaymentMethod] = useState();
  const [countBooked, setCountBooked] = useState();
  const [discountCode, setDiscountCode] = useState("");

  const [discountAmount, setDiscountAmount] = useState(0);
  const [disAmount, setDisAmount] = useState(0);
  const [subAmount, setSubAmount] = useState(0);
  const [amount, setAmount] = useState(0);
  const [feesAmount, setFeesAmount] = useState(0);
  const [extrafeeAmount, setExtraAmount] = useState(0);
  const [subTotal, setSubTotal] = useState(0);
  const [vatAmount, setVatAmount] = useState(0);
  const [depositRate, setDepositRate] = useState(0);
  const [depositAmount, setDepositAmount] = useState(0);
  const [taxAmount, setTaxAmount] = useState(0);
  const [netTotal, setNetTotal] = useState(0);
  const [showModalPayment, setShowModalPayment] = useState(false);
  const [showModalIban, setShowModalIban] = useState(false);
  const [showModalIbanInfo, setShowModalIbanInfo] = useState(false);
  const [paymentURL, setPaymentURL] = useState(false);

  const [swiftCodes, setSwiftCodes] = useState();
  const [swiftBankLogo, setSwiftBankLogo] = useState();
  const [showSwiftModal, setShowSwiftModal] = useState(false);
  const [userSwiftBank, setUserSwiftBank] = useState();

  const [toolTipHidden, setToolTipHidden] = useState(!false);
  const [ibanNum, setIbanNum] = useState();
  const userAgent = navigator.userAgent;


  const getSwift = async () => {
    setSwiftCodes(await getAllSwiftCodes());
  }

  const selectNewSwiftCode = async (val) => {
    setUserSwiftBank(val);
    setShowSwiftModal(false);
  }
  
  const changeSwiftBankLogo = () => {
    setShowSwiftModal(true);
    // swiftCodes?.allSwiftCodes.map((data) => {
    //   if(data.id === val.value){
    //     setUserSwiftBank(data);
    //   }
    // })
  }

  useEffect(() => {
    getAllSettings();
    getSwift();
    setSwiftBankLogo(me?.swift_code?.image)
    if(me?.iban_number?.length !== 24 && paymentDetails?.deposit > 0){
      setShowModalIban(true);
    }
    else if(paymentDetails.deposit > 0){
      setShowModalIbanInfo(true);
    }
    console.log(me)
  }, []);


  useEffect(() => {
    if(allSettings) {
      setFeesAmount(allSettings.admin_commission_rate);
      setVatAmount(allSettings.tax);
      setDepositRate(allSettings.deposit_rate);
    }
  }, [allSettings]);


  useEffect(() => {

    if(paymentDetails.term_type === "monthly") {
      setCountBooked(paymentDetails.duration);

      let sub_amount= parseFloat(paymentDetails.duration) * parseFloat(paymentDetails.price);
      let discount_amount = parseFloat(sub_amount) * (parseFloat(discountAmount)/100);
      let amount = parseFloat(sub_amount) - parseFloat(discount_amount);
      // let fees = parseFloat(amount) * (parseFloat(feesAmount)/100);
      let sub_total = parseFloat(amount);
      // let deposit_total = parseFloat(sub_total) * (parseFloat(depositRate)/100);
      let deposit_total = paymentDetails.deposit;
      let tax_amount = parseFloat(sub_total) * (parseFloat(vatAmount)/100); 
      let net_total = parseFloat(sub_total) + parseFloat(tax_amount) + parseFloat(deposit_total);

      setDepositAmount(deposit_total);
      setSubAmount(sub_amount);
      setDisAmount(discount_amount);
      // setExtraAmount(fees);
      setTaxAmount(tax_amount);
      setNetTotal(net_total);
    }
    else if(paymentDetails.term_type === "daily") {
      // let countDts = getDifferenceOnDate(startDt, endDt);
      let countDts = calcDates();
      // countDts = parseInt(countDts + 1)
      // if(countDts === 0) {
      //   countDts = 1;
      // }
      setCountBooked(countDts);

      let sub_amount= parseFloat(countDts) * parseFloat(paymentDetails.price);
      let discount_amount = parseFloat(sub_amount) * (parseFloat(discountAmount)/100);
      let amount = parseFloat(sub_amount) - parseFloat(discount_amount);
      // let fees = parseFloat(amount) * (parseFloat(feesAmount)/100);
      let sub_total = parseFloat(amount);
      // let deposit_total = parseFloat(sub_total) * (parseFloat(depositRate)/100);
      let deposit_total = paymentDetails.deposit;
      let tax_amount = parseFloat(sub_total) * (parseFloat(vatAmount)/100); 
      let net_total = parseFloat(sub_total) + parseFloat(tax_amount) + parseFloat(deposit_total);
      // console.log(calcDates());
      setDepositAmount(deposit_total);
      setSubAmount(sub_amount);
      setDisAmount(discount_amount);
      // setExtraAmount(fees);
      setTaxAmount(tax_amount);
      setNetTotal(net_total);
    }
    else if(paymentDetails.term_type === "hourly") {
      let hrsInbetween = paymentDetails.hrs_Inbetween;
      setCountBooked(hrsInbetween.length);

      let sub_amount= parseFloat(hrsInbetween.length) * parseFloat(paymentDetails.price);
      let discount_amount = parseFloat(sub_amount) * (parseFloat(discountAmount)/100);
      let amount = parseFloat(sub_amount) - parseFloat(discount_amount);
      // let fees = parseFloat(amount) * (parseFloat(feesAmount)/100);
      let sub_total = parseFloat(amount);
      // let deposit_total = parseFloat(sub_total) * (parseFloat(depositRate)/100);
      let deposit_total = paymentDetails.deposit;
      let tax_amount = parseFloat(sub_total) * (parseFloat(vatAmount)/100); 
      let net_total = parseFloat(sub_total) + parseFloat(tax_amount) + parseFloat(deposit_total);

      setDepositAmount(deposit_total);
      setSubAmount(sub_amount);
      setDisAmount(discount_amount);
      // setExtraAmount(fees);
      setTaxAmount(tax_amount);
      setNetTotal(net_total);
    }

  }, [paymentDetails, discountAmount, feesAmount, vatAmount]);

  const onChangeIban = (e) => {
    e.target.value = `${e.target.value?.substring(0,2).replace(/[^\dA-Z]/g, '').replace(/(.{2})/g, '$1 ').trim()}${e.target.value.length > 2 ? " " : ""}${e.target.value?.substring(2,e.target.value.length).replace(/[^\dA-Z]/g, '').replace(/(.{4})/g, '$1 ').trim()}`;
    setIbanNum(e.target.value);
  }

  const cancelBooking = () => {
    window.location.reload(false);
  }

  const closeIbanInfoModal = () => {
    setShowModalIbanInfo(false);
  }

  const onSaveIban = async (e) => {
    if(ibanNum?.length < 27){
      window.alert(
        "IBAN Number should be at least 24 characters"
      );
    }
    else{
      e.preventDefault();
      const formData = new FormData(e.target);
      let formdataObj = Object.fromEntries(formData.entries());
      const query = `
        mutation($input: UpdateUserInput!) {
          updateUser(input:$input){
            name
            phone
            role {
              ar
              en
            }
            email
            gender
          }
        }
      `;
      const ibanN = ibanNum.replaceAll(' ', '');
      let ibanNumFinal = "SA" + ibanN;

      let input = {
        avatar: null,
        name: me.name,
        email: me.email,
        address: me.address,
        gender: me.gender,
        bank_name: (me.bank_name === "" || me.bank_name === null || me.bank_name === undefined) ? "" : me.bank_name,
        // bank_account_num: me.bank_account_num,
        // bank_account_name: me.bank_account_name,
        iban_number: ibanNumFinal,
        swift_code_id: userSwiftBank?.id,

      };
      const operations = JSON.stringify({ query, variables: { input } });
      formData.append("operations", operations);

      const map = {
        "0": ["variables.input.avatar"],
      };
      formData.append("map", JSON.stringify(map));

        let result = await axios({
          url: baseURL,
          headers: apiHeaders,
          method: "post",
          data: formData
      });

      if(result.data.data) {
        getMe();
        Swal.fire({
          title: 'Updated IBAN Complete',
          text: "",
          icon: 'success',
          confirmButtonColor: '#FF5833',
        }).then((e) => {
          setShowModalIban(false);
        });
      }
    }
    
  }

  //Calculate the booked days for Daily
  const calcDates = () => {
    let start = false;
    let cdays = 0;
    paymentDetails.daily_dates_final.map((dates)=>{
      if(dates === paymentDetails.booking_date){
        start = true;
      }
      if(dates === paymentDetails.end_date){
        start = false
        cdays = cdays + 1;
      }
      if(start === true){
        if(paymentDetails.daily_dates_final.includes(dates)){
          cdays = cdays + 1;
        }
      }
    })
    return cdays;
  }

  const showTip = () => {
    setToolTipHidden(!toolTipHidden);
  }

  const onClickApplyDiscount = () => {
    getDiscount(discountCode);
  }

  useEffect(() => {
    if(discount) {
      if(discount.data.errors) {
        Swal.fire({
          title: 'Error',
          text: discount.data.errors[0].message,
          icon: 'warning',
          confirmButtonColor: '#FF5833',
        });
      }
      else {
        setDiscountAmount(discount.data.data.findDiscountCode.rate)
      }
    }
  }, [discount]);

  const handleCloseModalPayment = () => setShowModalPayment(false);

  //SELECTED PAYMENT METHOD
  //VISA
  let iframePayment = {
    payment:
      `<iframe width="100%" height="500" scrolling="no" frameborder="no" allow="autoplay" src=${baseURLPayment}/charge-card?token=4ZNJXwUW6m10CrNxONI&user_id=${paymentDetails.user_id}&app_used=web&status=success&payment_for=bookings_payment&post_lease_term_id=${paymentDetails.post_lease_term_id}&booking_date=${paymentDetails.booking_date}&end_date=${paymentDetails.end_date}&time_in=${paymentDetails.time_in}&time_out=${paymentDetails.time_out}&discount_code=${discountCode}&brand=VISA></iframe>`      
  };
  let iframePaymentApplePay = {
    payment:
      `<iframe width="100%" height="500" scrolling="no" frameborder="no" allow="autoplay" src=${baseURLPayment}/charge-card?token=4ZNJXwUW6m10CrNxONI&user_id=${paymentDetails.user_id}&app_used=web&status=success&payment_for=bookings_payment&post_lease_term_id=${paymentDetails.post_lease_term_id}&booking_date=${paymentDetails.booking_date}&end_date=${paymentDetails.end_date}&time_in=${paymentDetails.time_in}&time_out=${paymentDetails.time_out}&discount_code=${discountCode}&brand=APPLEPAY></iframe>`      
  };
  let iframePaymentMada = {
    payment:
      `<iframe width="100%" height="500" scrolling="no" frameborder="no" allow="autoplay" src=${baseURLPayment}/charge-card?token=4ZNJXwUW6m10CrNxONI&user_id=${paymentDetails.user_id}&app_used=web&status=success&payment_for=bookings_payment&post_lease_term_id=${paymentDetails.post_lease_term_id}&booking_date=${paymentDetails.booking_date}&end_date=${paymentDetails.end_date}&time_in=${paymentDetails.time_in}&time_out=${paymentDetails.time_out}&discount_code=${discountCode}&brand=MADA></iframe>`      
  };
  let iframePaymentMaster = {
    payment:
      `<iframe width="100%" height="500" scrolling="no" frameborder="no" allow="autoplay" src=${baseURLPayment}/charge-card?token=4ZNJXwUW6m10CrNxONI&user_id=${paymentDetails.user_id}&app_used=web&status=success&payment_for=bookings_payment&post_lease_term_id=${paymentDetails.post_lease_term_id}&booking_date=${paymentDetails.booking_date}&end_date=${paymentDetails.end_date}&time_in=${paymentDetails.time_in}&time_out=${paymentDetails.time_out}&discount_code=${discountCode}&brand=MASTER></iframe>`      
  };

  const onClickPayment = () => {

    if(me?.iban_number?.length !== 24 && paymentDetails?.deposit > 0){
      setShowModalIban(true);
    }
    else{
  // if(paymentMethod === "applePay") {
      //   window.location.href = `${baseURLPayment}/admin/charge-card?token=4ZNJXwUW6m10CrNxONI&user_id=${paymentDetails.user_id}&app_used=web&status=success&payment_for=bookings_payment&post_lease_term_id=${paymentDetails.post_lease_term_id}&booking_date=${paymentDetails.booking_date}&end_date=${paymentDetails.end_date}&time_in=${paymentDetails.time_in}&time_out=${paymentDetails.time_out}&discount_code=${discountCode}&brand=APPLEPAY`;
      // }
      // else {
      //   setShowModalPayment(true);
      // }

      if(paymentDetails.booking_date) {
        setShowModalPayment(true);
      }
      else {
        Swal.fire({
          title: 'Error',
          text: 'Please input the booking date',
          icon: 'warning',
          confirmButtonColor: '#FF5833',
        }).then((e) => {
          window.location.reload();
        });
      }
    }

    
    // window.location.href = `${baseURLPayment}/admin/charge-card?token=4ZNJXwUW6m10CrNxONI&user_id=${paymentDetails.user_id}&app_used=web&status=success&payment_for=bookings_payment&post_lease_term_id=${paymentDetails.post_lease_term_id}&booking_date=${paymentDetails.booking_date}&end_date=${paymentDetails.end_date}&time_in=${paymentDetails.time_in}&time_out=${paymentDetails.time_out}&discount_code=${discountCode}&brand=APPLEPAY`;
  }

  function Iframe(props) {
    return (
      <div
        dangerouslySetInnerHTML={{ __html: props.iframe ? props.iframe : "" }}
      />
    );
  }

  const onClickBack = () => {
    window.location.href = "/";
  }

  return (
    <div>
      <div className="container-fluid leases-payment-wrapper">
        <div className="container main-wrap">
          <div className="row">
            <div className="col-12">
              <h2 className="fw700 cblue mb-5">
                <FormattedMessage 
                  id="leases.text"
                  defaultMessage="Leases"
                  description="Leases"
                />
              </h2>
            </div>

            <div className="col-12 col-lg-7">
              <div className="pm-outer-div mb-4">
                <div className="pm-inner-div">

                  <div className="d-flex justify-content-between mb-3">
                    <p className="cblack fs14 mb-0">
                      <FormattedMessage 
                        id="booktotal.text"
                        defaultMessage="Book Total"
                        description="Book Total"
                      />
                    </p>
                    <p className="fw700 cblack fs14 mb-0">SAR {paymentDetails.price} x {countBooked}</p>
                  </div>

                  <div className="d-flex justify-content-between mb-3">
                    <p className="fw700 cblack fs16 mb-0">
                      <FormattedMessage 
                        id="subtotal.text"
                        defaultMessage="Subtotal"
                        description="Subtotal"
                      />
                    </p>
                    <p className="fw700 cblack fs16 mb-0">SAR {Math.round(100*subAmount)/100}</p>
                  </div>

                  <div className="d-flex justify-content-between mb-3">
                    <p className="fw700 cblack fs16 mb-0">
                      <FormattedMessage 
                        id="discount.text"
                        defaultMessage="Discount"
                        description="Discount"
                      />
                    </p>
                    <p className="fw700 cblack fs16 mb-0">{Math.round(100*disAmount)/100}</p>
                  </div>

                  <div className="d-flex justify-content-between mb-3">
                    <p className="fw700 cblack fs16 mb-0">
                      <FormattedMessage 
                        id="extrafees.text"
                        defaultMessage="Extra Fees"
                        description="Extra Fees"
                      />
                    </p>
                    <p className="fw700 cblack fs16 mb-0">{Math.round(100*extrafeeAmount)/100}</p>
                  </div>

                  <div className="d-flex justify-content-between mb-3">
                    <p className="fw700 cblack fs16 mb-0">
                        <FormattedMessage 
                          id="deposit.text"
                          defaultMessage="Deposit"
                          description="Deposit"
                        />
                    </p>
                    <p className="fw700 cblack fs16 mb-0">
                      {/* {Math.round(100*depositAmount)/100} */}
                      {depositAmount}
                    </p>
                    
                  </div>

                  <div className="d-flex justify-content-between mb-3">
                    <p className="fw700 cblack fs16 mb-0">
                      <FormattedMessage 
                        id="vat.text"
                        defaultMessage="VAT"
                        description="VAT"
                      /> ({vatAmount}%)</p>
                    <p className="fw700 cblack fs16 mb-0">SR {Math.round(100*taxAmount)/100}</p>
                  </div>

                  <div className="divider-dashed mb-4"></div>

                  <div className="d-flex justify-content-between">
                    <p className="fw700 cblack fs20 mb-0">
                      <FormattedMessage 
                        id="total.text"
                        defaultMessage="Total"
                        description="Total"
                      />
                    </p>
                    <p className="fw700 cblack fs20 mb-0">SR {Math.round(100*netTotal)/100}</p>
                  </div>
                </div>

                <div className="discount-div mt-4">
                  <input type="text" className="form-control" placeholder={intl.formatMessage({
                    id: 'enterdiscode.text',
                    defaultMessage: "Enter discount code",
                    description: "Enter discount code",
                  })} defaultValue={discountCode} onChange={(e) => setDiscountCode(e.target.value)} />
                  <button className="btn" onClick={onClickApplyDiscount}>
                    <FormattedMessage 
                      id="apply.text"
                      defaultMessage="Apply"
                      description="Apply"
                    />
                  </button>
                </div>

              </div>
            </div>
            <div className="col-12 col-lg-5">
              <div className="pm-right-div">
                <p className="fw700 fs18 cblack">
                  <FormattedMessage 
                    id="paymentmethod.text"
                    defaultMessage="Payment Method"
                    description="Payment Method"
                  />
                </p>

                  {/* {
                    !userAgent?.includes("Android") &&
                  <div className="form-check px-5 mb-3">
                    <input className="" type="radio" name="payment_method" id="apple-pay" value="applePay"
                      checked={paymentMethod === "applePay"}
                      onChange={(e) => setPaymentMethod(e.target.value)}
                    />
                    <label className="form-check-label cblack" htmlFor="apple-pay">
                      <FormattedMessage 
                        id="applepay.text"
                        defaultMessage="Apple Pay"
                        description="Apple Pay"
                      />
                    </label>
                    <img className={"paymentLogo mada"} src={applepaylogo}/>
                 </div>
                  } */}
                
                <div className="form-check px-4 mb-3">
                  <input className="" type="radio" name="payment_method" id="credit-card" value="masterCard"
                    checked={paymentMethod === "masterCard"}
                    onChange={(e) => setPaymentMethod(e.target.value)}
                  />
                  <label className="form-check-label cblack" htmlFor="credit-card">
                    <FormattedMessage 
                      id="creditcard.text"
                      defaultMessage="Master card"
                      description="Master card"
                    />
                  </label>
                  <img className={"paymentLogo"} src={masterlogo}/>
                </div>
                <div className="form-check px-4 mb-3">
                  <input className="" type="radio" name="payment_method" id="master-card" value="visaCard"
                    checked={paymentMethod === "visaCard"}
                    onChange={(e) => setPaymentMethod(e.target.value)}
                  />
                  <label className="form-check-label cblack" htmlFor="master-card">
                    <FormattedMessage 
                      id="visacard.text"
                      defaultMessage="Visa Card"
                      description="Visa Card"
                    />
                  </label>
                  <img className={"visapaymentLogo"} src={visalogo}/>
                </div>
                <div className="form-check px-4 mb-3">
                  <input className="" type="radio" name="payment_method" id="mada-card" value="madaCard"
                    checked={paymentMethod === "madaCard"}
                    onChange={(e) => setPaymentMethod(e.target.value)}
                  />
                  <label className="form-check-label cblack" htmlFor="mada-card">
                    <FormattedMessage 
                      id="madacard.text"
                      defaultMessage="Mada"
                      description="Mada"
                    />
                  </label>
                  <img className={"paymentLogo mada"} src={madalogo}/>
                </div>

                <div className="d-flex flex-row align-items-center mt-3">
                  <div className="position-relative d-flex px-2">
                    <svg onMouseEnter={showTip} onMouseLeave={showTip} style={{cursor: "pointer", marginRight:"10px", color: "#808080", position: "relative", marginTop: "3px" }} xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" className="bi bi-question-circle" viewBox="0 0 16 16">
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
                      <path d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286m1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94"/>
                    </svg>
                    <div className={`${toolTipHidden ? "hidden": null} ${intl.locale === "en" ? "deposit-info-toggle":"deposit-info-toggle-ar"}`}>
                        <FormattedMessage 
                          // id="depositinsurance.text"
                          // defaultMessage="A deposit serves as insurance for the lessor by providing a financial buffer against potential damages, or breaches of the lease agreement by the tenant. It offers reassurance that if the tenant fails to fulfill their obligations, the lessor has a means to cover costs and mitigate losses, ensuring the item's upkeep and financial security."
                          // description="A deposit serves as insurance for the lessor by providing a financial buffer against potential damages, or breaches of the lease agreement by the tenant. It offers reassurance that if the tenant fails to fulfill their obligations, the lessor has a means to cover costs and mitigate losses, ensuring the item's upkeep and financial security."
                          id="deposit_info.text"
                          defaultMessage="Deposit will be refunded once the lessor confirms that the product is returned safely"
                          description="Deposit will be refunded once the lessor confirms that the product is returned safely"
                        />
                    </div>
                  </div>
                  <p className="fw500 cblack fs14 mb-0 mt-1">
                    <FormattedMessage 
                      id="readmoreaboutdeposit.text"
                      defaultMessage="Read more about deposit"
                      description="Read more about deposit"
                    />
                  </p>
                </div>
              </div>


              <button className="btn btn-payment fw700" disabled={paymentMethod ? false : true} onClick={onClickPayment}>
                <FormattedMessage 
                  id="proceedpayment.text"
                  defaultMessage="Proceed to Payment"
                  description="Proceed to Payment"
                />
              </button>
              <button className="btn btn-back w-100 mt-3" onClick={onClickBack}>
                <FormattedMessage 
                  id="backtohome.text"
                  defaultMessage="Back to Home"
                  description="Back to Home"
                />
              </button>
              

              {/* Modal payment */}
              <Modal className="modalChat" show={showModalPayment} onHide={handleCloseModalPayment}>
                <Modal.Body className="p-0">
                  {paymentMethod === "applePay" ? (
                    <Iframe iframe={iframePaymentApplePay["payment"]} allow="autoplay"/>
                  ) : 
                  paymentMethod === "madaCard" ? (
                    <Iframe iframe={iframePaymentMada["payment"]} allow="autoplay"/>
                  ) :
                  paymentMethod === "masterCard" ? (
                    <Iframe iframe={iframePaymentMaster["payment"]} allow="autoplay"/>
                  ) : (
                    <Iframe iframe={iframePayment["payment"]} allow="autoplay"/>
                  )}
                </Modal.Body>
              </Modal>

              <Modal className="modalSwift" show={showSwiftModal} onHide={() => setShowSwiftModal(false)}>
                <Modal.Header className="flex-row-reverse justify-end">
                  {/* <Modal.Title className="m-auto">
                  <svg xmlns="http://www.w3.org/2000/svg" className={`m-2`} width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><circle cx="12" cy="12" r="10"></circle><line x1="12" y1="16" x2="12" y2="12"></line><line x1="12" y1="8" x2="12.01" y2="8"></line></svg>
                    <FormattedMessage 
                        id="information.text"
                        defaultMessage="Information"
                        description="Information"
                      />  
                  </Modal.Title> */}
                  <Button className="justify-self-end" variant="secondary" onClick={()=>setShowSwiftModal(false)}>Close</Button>
                </Modal.Header>
                <Modal.Body>
                  <div className={"swiftContainer"}>
                    {
                      swiftCodes?.allSwiftCodes?.map((swift, index)=>{
                        return(
                            <div className="swiftlist" key={index} value={swift.id} onClick={()=>selectNewSwiftCode(swift)}>
                              <img className="swiftbanklogoselect" alt="bank-logo" src={swift.image}/>
                              <p className="mytext ">{intl.locale === "en" ? swift?.bank_name?.toUpperCase() : swift?.bank_name_ar?.toUpperCase()}</p>
                            </div>
                        )
                      })
                    }
                  </div>
                </Modal.Body>
              </Modal>      


              <Modal className="modalIban" show={showModalIban}>
                <Modal.Header>
                  <Modal.Title className="m-auto">
                    <FormattedMessage 
                        id="ibannumberisrequired.text"
                        defaultMessage="IBAN Number is required"
                        description="IBAN Number is required"
                      />  
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body className="p-4 m-auto">
                <form className="my-profile-div" onSubmit={onSaveIban}>
                  <p>
                    <FormattedMessage 
                      id="thedepositwillbe.text"
                      defaultMessage="The deposit will be credited to this account once booking is settled."
                      description="The deposit will be credited to this account once booking is settled."
                    />
                  </p>
                  <div className="input-group iban-grp-payment-form">
                    <div className="prof-per-div upper-part-swiftcode">
                      {/* <label className="cgray fs14 left">
                        <FormattedMessage 
                          id="banknamebic.text"
                          defaultMessage="Bank Name (BIC)"
                          description="Bank Name (BIC)"
                        />
                      </label> */}
                      {
                        userSwiftBank && 
                        <img className="swiftbanklogo" alt="bank-logo" src={userSwiftBank.image}/>
                      }
                      
                    <input type="text" className="form-control" name="swiftcode" placeholder={intl.formatMessage({
                          id: 'selectswiftcode.text',
                          defaultMessage: 'Enter Bank Name (BIC)',
                          description: 'Enter Bank Name (BIC)',
                        })} defaultValue={me && intl.locale === "en" ? userSwiftBank?.bank_name : userSwiftBank?.bank_name_ar} onClick={()=>changeSwiftBankLogo()} readOnly/>
                    </div>
                    <div className="prof-per-div">
                      <span className="input-group-text" id="basic-addon1">SA</span>
                      <input type="text" minLength={27} maxLength={27} className="form-control" name="iban_number" placeholder={intl.formatMessage({
                        id: 'ibannum.text',
                        defaultMessage: "Enter IBAN number",
                        description: "Enter IBAN number",
                      })} defaultValue={ibanNum} onChange={onChangeIban} required/>
                    </div>
                  </div>
                  <button type="submit" className="cursorPointer mt-2 btn-save-iban" disabled={(ibanNum?.length >= 27 && userSwiftBank)? false : true}>
                    <FormattedMessage 
                      id="savechanges.text"
                      defaultMessage="Save Changes"
                      description="Save Changes"
                    />
                  </button>
                </form>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={cancelBooking}>Close</Button>
                </Modal.Footer>
              </Modal>

              <Modal className="modalIban" show={showModalIbanInfo}>
                <Modal.Header>
                  <Modal.Title className="m-auto">
                  <svg xmlns="http://www.w3.org/2000/svg" className={`m-2`} width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><circle cx="12" cy="12" r="10"></circle><line x1="12" y1="16" x2="12" y2="12"></line><line x1="12" y1="8" x2="12.01" y2="8"></line></svg>
                    <FormattedMessage 
                        id="information.text"
                        defaultMessage="Information"
                        description="Information"
                      />  
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body className="p-4 m-auto">
                <form className="my-profile-div" onSubmit={onSaveIban}>
                  <p>
                    <FormattedMessage 
                      id="thedepositwillbe.text"
                      defaultMessage="The deposit will be credited to this account once booking is settled."
                      description="The deposit will be credited to this account once booking is settled."
                    />
                  </p>
                  <div className="iban-grp mb-3">
                    <input type="text" minLength={27} maxLength={27} className="form-control" name="iban_number" placeholder={intl.formatMessage({
                      id: 'ibannum.text',
                      defaultMessage: "Enter IBAN number",
                      description: "Enter IBAN number",
                  })} defaultValue={me?.iban_number} onChange={onChangeIban} readOnly required/>
                  </div>
                </form>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={closeIbanInfoModal}>Close</Button>
                </Modal.Footer>
              </Modal>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LeasePayment;
